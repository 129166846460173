                                                    
                                            
                
                        
                          

                   


                 
                          
                      

                   
                                                                                      

           
                                   
                      
                        
                            
                                                             
             
                       
                           

                               
         
                                      
                                                                    
                                                                         
                                                                               
                                                     
              
                                
                                   
                                                          
                        
              
          
       


                 
                          
                      
                        

                         
                        
                               
                             

                                  
                         
                                           
                                    
                                  
                        

                         
                          
                          
                                      

                               
                                                                  
                                                
                                                                                 
                               
                
               
                        
                                                 
                                                              
                           
                          
                                                  
                                              
         
                                                          
                                             
                 
          
       

                            
                    
                           
                           
                                             
          
                         
                                                                  
                                  
             
         
     
       

                    
    .todo-strike {
        text-decoration: line-through;
    }
    